import { Application } from '@pixi/app'
import {Sprite} from "@pixi/sprite";
import {BatchRenderer, Renderer, Texture} from "@pixi/core";
import {ParticleRenderer,ParticleContainer} from "@pixi/particle-container";
import {Container} from "@pixi/display";
import {TickerPlugin} from "@pixi/ticker";
import {ColorMatrixFilter} from "@pixi/filter-color-matrix";
import {RGBSplitFilter} from "@pixi/filter-rgb-split";
import {settings} from "@pixi/settings";
import {SCALE_MODES} from "@pixi/constants";

Renderer.registerPlugin('batch', BatchRenderer);
Application.registerPlugin(TickerPlugin);
Renderer.registerPlugin('particle', ParticleRenderer);

export const Rat = (element, ratId, apiUrl, resizeStage) => {
    let size = 1;
    if(resizeStage === true) {
        size = Math.min(window.innerHeight / 256, window.innerWidth / 256);
    }
    const _pixiApp = new Application({
        width: 256 * size,
        height: 256 * size,
        autoResize: true,
        backgroundAlpha: 0
    });
    settings.SCALE_MODE = SCALE_MODES.LINEAR;
    element.appendChild(_pixiApp.view);
    const ratContainer = new Container();
    _pixiApp.stage.addChild(ratContainer);
    ratContainer.filters = [];

    if(resizeStage === true) {
        _pixiApp.stage.scale.x = size;
        _pixiApp.stage.scale.y = size;
    }

    const matrixFilter = new ColorMatrixFilter();
    const rgbFilter = new RGBSplitFilter();

    let sprites = [];
    let lastInteraction = 0;
    let lastAwake = 0;
    let lastAsleep = -1000;
    let eating = -1000;
    let elapsed = 0.0;
    let awake = 0.0;
    let happy = -1000;
    let blingSpeed = 5;
    let bling1 = 0;
    let bling2 = 0;
    let bling3 = 0;
    let cameraflash = 0;
    let lightsaberFlash = 0;
    let scytheBling = 0;
    /* let glitchTimer = 0; */

    const UPPER_LIMIT_Y = 1
    const UPPER_LIMIT_X = 1
    const MAX_SIZE = 6
    const MIN_SIZE = 2

    /** Interaction **/
    window.onmousemove = function() {
        lastInteraction = elapsed;
    };
    window.onmousedown = function() {
        var rand = Math.round(Math.random() * 4);
        if(rand === 1) {
            eating = elapsed;
        } else {
            happy = elapsed + 100;
        }
        lastInteraction = elapsed;
    };

    const floored = v => Math.floor(Math.random() * v)
    const genParticles = (t, amount) =>
        new Array(amount).fill().map(p => {
            const SIZE = floored(MAX_SIZE) + MIN_SIZE
            p = new Sprite(t)
            p.size = SIZE
            p.vx = floored(UPPER_LIMIT_X) - UPPER_LIMIT_X
            p.vy = floored(UPPER_LIMIT_Y) + 2
            p.x = p.startX = floored(_pixiApp.screen.width)
            p.y = p.startY = -(SIZE + floored(_pixiApp.screen.height))

            p.seed = floored(10000);
            if(sprites['bg13']) {
                p.tint = 0x00AA00;
                p.width = p.height = 8;
            }
            if(sprites['bg_snow']) {
                p.width = p.height = SIZE
            }
            drops.addChild(p)
            return p
        });

    const drops = new ParticleContainer(10, {
        position: true,
        alpha: true,
    });

    const baseTexture = Texture.WHITE;
    let filters = [];

    ratContainer.filters.push(matrixFilter);

    let spriteLoader = (callback) => {
        fetch(apiUrl).then((response) => {
            return response.json();
        }).then((data) => {
            if(typeof data.filters !== 'undefined') {
                filters = data.filters;
            }
            let bundle = {};
            for(var i in data.layers) {
                if(typeof data.layers[i].data !== 'undefined') {
                    bundle['encoded__' + data.layers[i].name] = 'data:image/png;base64,' + data.layers[i].data;
                }
                if(data.layers[i].name === 'cig' || data.layers[i].name === 'pipe') {
                    bundle[data.layers[i].name] = '/sprites/' + data.layers[i].file;
                    if(data.layers[i].name === 'pipe') {
                        bundle['smoke1'] = '/sprites/expressions/smoke1.png';
                    }
                    bundle['smoke2'] = '/sprites/expressions/smoke2.png';
                    bundle['smoke3'] = '/sprites/expressions/smoke3.png';
                    bundle['smoke4'] = '/sprites/expressions/smoke4.png';
                    continue;
                }
                switch(data.layers[i].name) {
                    case 'bling1':
                    case 'bling2':
                    case 'bling3':
                    case 'mod-flash':
                    case 'purge-yellow-eyes':
                    case 'purge-yellow-outline':
                    case 'purge-yellow-mouth':
                    case 'purge-red-eyes':
                    case 'purge-red-outline':
                    case 'purge-red-mouth':
                    case 'lightsaber-flash':
                    case 'scythe-bling':
                    case 'third-eye-sleep':
                    case 'z-big':
                    case 'z-small':
                        continue;
                }
                if(data.layers[i].name === 'neutral'
                    || data.layers[i].name === 'eye_green'
                    || data.layers[i].name === 'eye_red'
                    || data.layers[i].name === 'eye_blue'
                    || data.layers[i].name === 'eyes-alien'
                    || data.layers[i].name === 'eye_purple') {
                    bundle['blink'] = '/sprites/expressions/blink.png';
                    bundle['happy'] = '/sprites/expressions/happy.png';
                    bundle['eating'] = '/sprites/expressions/happy.png';
                    bundle['nope'] = '/sprites/expressions/nope.png';
                    bundle['rest'] = '/sprites/expressions/rest.png';
                    bundle['third-eye-sleep'] = '/sprites/halloween2023/third-eye-sleep.png';
                    bundle['neutral'] = '/sprites/' + data.layers[i].file;
                } else {
                    bundle[data.layers[i].name] = '/sprites/' + data.layers[i].file;
                }
                if(data.layers[i].name === 'bg13') {
                    /* es-lint-disable ratContainer.filters.push(glitchFilter);*/
                }
                if(data.layers[i].name === '3d' || data.layers[i].name === '3d-v2') {
                    ratContainer.filters.push(rgbFilter);
                }
                if(data.layers[i].name === 'bgfireworks') {
                    bundle['fireworks1'] = '/sprites/swap/fireworks1.png';
                    bundle['fireworks2'] = '/sprites/swap/fireworks2.png';
                    bundle['fireworks3'] = '/sprites/swap/fireworks3.png';
                    bundle['fireworks4'] = '/sprites/swap/fireworks4.png';
                    bundle['fireworks5'] = '/sprites/swap/fireworks5.png';
                }
                if(data.layers[i].name === 'purge-base') {
                    bundle['purge-yellow-eyes'] = '/sprites/purge/purge-yellow-eyes.png';
                    bundle['purge-yellow-outline'] = '/sprites/purge/purge-yellow-outline.png';
                    bundle['purge-yellow-mouth'] = '/sprites/purge/purge-yellow-mouth.png';
                    bundle['purge-red-eyes'] = '/sprites/purge/purge-red-eyes.png';
                    bundle['purge-red-outline'] = '/sprites/purge/purge-red-outline.png';
                    bundle['purge-red-mouth'] = '/sprites/purge/purge-red-mouth.png';
                }
                if(data.layers[i].name === 'chain') {
                    bundle['bling1'] = '/sprites/expressions/bling1.png';
                    bundle['bling2'] = '/sprites/expressions/bling2.png';
                    bundle['bling3'] = '/sprites/expressions/bling3.png';
                }
                if(
                    data.layers[i].name === 'led-antlers-red'
                    || data.layers[i].name === 'led-antlers-blue'
                    || data.layers[i].name === 'led-antlers-yellow'
                    || data.layers[i].name === 'led-antlers-green'
                ) {
                    bundle['led-antlers-red'] = '/sprites/swap/led-antlers-red.png';
                    bundle['led-antlers-blue'] = '/sprites/swap/led-antlers-blue.png';
                    bundle['led-antlers-yellow'] = '/sprites/swap/led-antlers-yellow.png';
                    bundle['led-antlers-green'] = '/sprites/swap/led-antlers-green.png';
                }
                if(data.layers[i].name === 'mod-phone') {
                    bundle['mod-flash'] = '/sprites/summer/mod-flash.png';
                }
                if(data.layers[i].name.slice(0, 10) === 'lightsaber' && data.layers[i].name.slice(11, 17) !== 'handle') {
                    bundle['lightsaber-flash'] = '/sprites/halloween2023/lightsaber-flash.png';
                }
                if(data.layers[i].name === 'scythe') {
                    bundle['scythe-bling'] = '/sprites/halloween2023/scythe-bling.png';
                }
            }
            bundle['zbig'] = '/sprites/expressions/z-big.png';
            bundle['zsmall'] = '/sprites/expressions/z-small.png';
            bundle['food'] = '/sprites/expressions/food.png';
            bundle['food-particle1'] = '/sprites/expressions/food.png';
            bundle['food-particle2'] = '/sprites/expressions/food.png';
            bundle['food-particle3'] = '/sprites/expressions/food.png';

            for (var index in bundle) {
                var sprite = Sprite.from(bundle[index]);
                ratContainer.addChild(sprite);
                sprite._x = 0;
                sprite._y = 0;
                sprites[index] = sprite;

                if (index.toString().substring(0, 7) === 'encoded' && index.toString().substring(0, 11) !== 'encoded__bg') {
                    sprites[index].scale.set(8, 8);
                }

                if (index === 'bg_snow' || index === 'bg13') {
                    drops.x = 0;
                    drops.y = 256;
                    ratContainer.addChild(drops);
                }
            }
            sprites['food-particle1'].pivot.x = 20;
            sprites['food-particle1'].pivot.y = 20;
            sprites['food-particle2'].pivot.x = 20;
            sprites['food-particle2'].pivot.y = 20;
            sprites['food-particle3'].pivot.x = 20;
            sprites['food-particle3'].pivot.y = 20;

            callback();
        });
    };

    let changeThirdEye = function(mood) {
        if(sprites['third-eye']) {
            showThirdEyeMood('third-eye', mood)
            return
        }
        if(sprites['third-eye-blue']) {
            showThirdEyeMood('third-eye-blue', mood)
            return
        }
        if(sprites['third-eye-red']) {
            showThirdEyeMood('third-eye-red', mood)
            return
        }
        if(sprites['third-eye-green']) {
            showThirdEyeMood('third-eye-green', mood)
            return
        }
        if(sprites['thirdeye-purple']) {
            showThirdEyeMood('thirdeye-purple', mood)
            return
        }
        if(sprites['third-eye-alien']) {
            showThirdEyeMood('third-eye-alien', mood)
            return
        }
        if(sprites['third-eye-sleep']) {
            sprites['third-eye-sleep'].alpha = 0;
        }
    }
    let showThirdEyeMood = function(eyeType, mood) {
        switch(mood) {
            case 'rest':
                sprites[eyeType].alpha = 0;
                sprites['third-eye-sleep'].alpha = 1;
                break;
            default:
                sprites[eyeType].alpha = 1;
                sprites['third-eye-sleep'].alpha = 0;
                break;
        }
    }

    let moods = ['happy', 'nope', 'rest', 'neutral', 'blink', 'eating'];
    let showMood = (mood, elapsed) => {
        if(typeof sprites[mood] !== 'undefined') {
            sprites[mood].alpha = 1;
            for (var i in sprites) {
                if (moods.includes(i) && mood !== i) {
                    sprites[i].alpha = 0;
                }
            }
        }
        if(mood === 'neutral') {
            if(sprites['lazer'] && sprites['lazer'].alpha === 0) {
                sprites['lazer'].alpha = 1;
            }
            if(sprites['focus'] && sprites['focus'].alpha === 0) {
                sprites['focus'].alpha = 1;
            }
        } else {
            if(sprites['lazer']) {
                sprites['lazer'].alpha = 0;
            }
            if(sprites['focus']) {
                sprites['focus'].alpha = 0;
            }
        }
        if(mood === 'eating') {
            sprites['food'].alpha = 1;
            if(sprites['cig']) {
                sprites['cig'].alpha = 0;
                sprites['smoke1'].alpha = 0;
                sprites['smoke2'].alpha = 0;
                sprites['smoke3'].alpha = 0;
                sprites['smoke4'].alpha = 0;
            }
            if(sprites['medkit']) {
                sprites['medkit'].alpha = 0;
            }
            if(sprites['flower']) {
                sprites['flower'].alpha = 0;
            }
            if(sprites['lazer']) {
                sprites['lazer'].alpha = 0;
            }
            if(sprites['focus']) {
                sprites['focus'].alpha = 0;
            }
        } else if(mood === 'rest') {
            if (elapsed % 140 > 70) {
                sprites['zbig'].alpha = 1;
                sprites['zsmall'].alpha = 0;
            } else {
                sprites['zbig'].alpha = 0;
                sprites['zsmall'].alpha = 1;
            }

            changeThirdEye(mood)

            if(sprites['lazer']) {
                sprites['lazer'].alpha = 0;
            }
            if(sprites['focus']) {
                sprites['focus'].alpha = 0;
            }
        } else {
            sprites['zbig'].alpha = 0;
            sprites['zsmall'].alpha = 0;
            sprites['food'].alpha = 0;

            sprites['food-particle1'].alpha = 0;
            sprites['food-particle2'].alpha = 0;
            sprites['food-particle3'].alpha = 0;
            if(sprites['cig']) {
                sprites['cig'].alpha = 1;
            }

            changeThirdEye(mood)

            if(sprites['medkit']) {
                sprites['medkit'].alpha = 1;
            }
        }
    };

    let eatingParticles = function(elapsed) {
        var rX = 20;
        var rY = 240;

        if(filters.includes('flipped')) {
            rY = 80;
        }
        var speed = 7;
        sprites['food-particle1']._x = rX - (Math.cos(elapsed / speed))*100;
        sprites['food-particle1']._y = rY + (Math.sin(elapsed / speed))*100;
        sprites['food-particle1'].rotation = Math.tan(elapsed / 13);
        if(sprites['food-particle1']._y > (rY - 42)) {
            sprites['food-particle1'].alpha = 0;
        } else {
            sprites['food-particle1'].alpha = 1;
        }

        sprites['food-particle2']._x = rX + 230 + (Math.cos((elapsed+123) / speed))*100;
        sprites['food-particle2']._y = rY + (Math.sin((elapsed+123) / speed))*150;
        sprites['food-particle2'].rotation = Math.sin(elapsed / 10);
        if(sprites['food-particle2']._y > (rY - 42)) {
            sprites['food-particle2'].alpha = 0;
        } else {
            sprites['food-particle2'].alpha = 1;
        }

        sprites['food-particle3']._x = rX + 230 - 100 + (Math.cos((elapsed+201) / speed))*100;
        sprites['food-particle3']._y = rY + 56 + (Math.sin((elapsed+201) / speed))*100;
        sprites['food-particle3'].rotation = Math.cos(elapsed / 19);
        if(sprites['food-particle3']._x < (rX + 116) || sprites['food-particle3']._y > (rY + 50)) {
            sprites['food-particle3'].alpha = 0;
        } else {
            sprites['food-particle3'].alpha = 1;
        }
    }

    let setPosition = (x, y) => {
        for(var i in sprites) {
            if (i.substring(0, 2) === 'bg' || i.substring(0, 11) === 'encoded__bg' || i === 'spiderweb') {
                continue;
            }
            if(filters.includes('flipped') && i.substr(0, 13) !== 'food-particle') {
                sprites[i].x = (0 - x) + sprites[i]._x;
                sprites[i].y = (0 - y) + sprites[i]._y;
            } else {
                sprites[i].x = x + sprites[i]._x;
                sprites[i].y = y + sprites[i]._y;
            }
        }
    }

    spriteLoader(function() {
        showMood('neutral');
        if(filters.includes('flipped')) {
            for(var j in sprites) {
                if(j.substring(0, 2) == 'bg' || j.substring(0, 11) === 'encoded__bg' || j.substring(0, 13) === 'food-particle') {
                    continue;
                }
                sprites[j].anchor.set(1, 1);
                sprites[j].angle = 180;
            }
        }

        let randomBlink = (400 + Math.random() * 400);
        let particles = genParticles(baseTexture, 1);
        let purgeList = [
            'purge-yellow-eyes',
            'purge-red-eyes',
            'purge-yellow-outline',
            'purge-red-outline',
            'purge-yellow-mouth',
            'purge-red-mouth',
        ];
        let purgeBlink = 0;
        drops.removeChildren();

        if(sprites['bg_snow']) {
            particles = genParticles(baseTexture, 10);
        }
        if(sprites['bg13']) {
            particles = genParticles(baseTexture, 5);
        }
        if(sprites['bgfireworks']) {
            particles = genParticles(baseTexture, 100);
        }
        if(sprites['purge-base']) {
            for (let i in purgeList) {
                sprites[purgeList[i]].alpha = 0;
            }
        }
        const updateAnimation = (delta) => {
            let moodOverwrite = false;
            if(sprites['bg_snow'] || sprites['bg13']) {
                for (let particle of particles) {
                    if (sprites['bg_snow']) {
                        particle.x += delta * Math.cos((particle.y + particle.seed) / 50) * 2;
                        particle.y += delta * particle.vy
                    }
                    if (sprites['bg13']) {
                        particle.alpha = floored(2) / 2;
                        particle.y += delta * (particle.vy * 7);
                        particle.tint = (floored(4) == 1 ? 0xFFFFFF : 0x00AA00);
                    }
                    if (
                        particle.x > _pixiApp.screen.width ||
                        particle.x < 0 ||
                        particle.y > _pixiApp.screen.height
                    ) {
                        if (sprites['bg13']) {
                            particle.x = Math.floor(floored(_pixiApp.screen.width) / 8) * 8;
                        }
                        if (sprites['bg_snow']) {
                            particle.x = floored(_pixiApp.screen.width)
                        }
                        particle.y = -(256 + floored(_pixiApp.screen.height))
                    }
                }
            }

            elapsed += delta;
            if(sprites['3d'] || sprites['3d-v2']) {
                var offset = Math.min((Math.cos(elapsed / 100.0)), 0);
                var slowOffset = Math.min((Math.cos(elapsed / 250.0)), 0);
                rgbFilter.red.x = offset * 7;
                rgbFilter.red.y = slowOffset * 7;
                rgbFilter.blue.x = offset * 7;
                rgbFilter.blue.y = (0 - offset) * 7;
                rgbFilter.green.x = 0;//Math.sin(elapsed / 85.0) * 5;
                rgbFilter.green.y = 0;//Math.cos(elapsed / 85.0) * 5;
            }
            if(sprites['purge-base']) {
                if(purgeBlink < elapsed) {
                    let random1 = Math.round((Math.random() * 100000000)) % 8;

                    switch(random1) {
                        case 0:
                            sprites[purgeList[0]].alpha = 1;
                            sprites[purgeList[1]].alpha = 0;
                            break;
                        case 1:
                            sprites[purgeList[0]].alpha = 0;
                            sprites[purgeList[1]].alpha = 1;
                            break;
                        case 2:
                            sprites[purgeList[2]].alpha = 0;
                            sprites[purgeList[3]].alpha = 1;
                            sprites[purgeList[4]].alpha = 0;
                            sprites[purgeList[5]].alpha = 1;
                            break;
                        case 3:
                            sprites[purgeList[2]].alpha = 1;
                            sprites[purgeList[3]].alpha = 0;
                            sprites[purgeList[4]].alpha = 1;
                            sprites[purgeList[5]].alpha = 0;
                            break;
                        case 4:
                        case 6:
                            sprites[purgeList[0]].alpha = 0;
                            sprites[purgeList[1]].alpha = 0;
                            break;
                        case 5:
                        case 7:
                            sprites[purgeList[2]].alpha = 0;
                            sprites[purgeList[3]].alpha = 0;
                            sprites[purgeList[4]].alpha = 0;
                            sprites[purgeList[5]].alpha = 0;
                            break;
                    }


                    purgeBlink = elapsed + (Math.random()*10000000000) % 50;
                }
            }
            if(sprites['halo']) {
                sprites['halo']._y = 3 + Math.cos(elapsed / 85.0) * 2.0;
            }
            if(sprites['nope']) {
                sprites['nope']._y = 1 + (Math.cos(elapsed / 15.0)) * 2;
            }
            if(sprites['flower']) {
                sprites['flower']._y = 3 + Math.cos(elapsed / 20.0);
            }
            if(sprites['medkit']) {
                sprites['medkit']._y = 1 + Math.cos(elapsed / 100.0);
            }
            if(sprites['mustache']) {
                sprites['mustache'].rotation = Math.cos(elapsed/80.0) * 0.015;
            }
            if(sprites['mustache-white']) {
                sprites['mustache-white'].rotation = Math.cos(elapsed/80.0) * 0.01;
            }
            if(sprites['focus'] && sprites['focus'].alpha !== 0) {
                sprites['focus'].alpha = 1 - (Math.cos(elapsed/20.0) / 5);
            }
            if(sprites['lazer'] && sprites['lazer'].alpha !== 0) {
                sprites['lazer'].alpha = 1 - (Math.cos(elapsed/10.0) / 5);
            }
            if(sprites['glow']) {
                sprites['glow'].alpha = 1 - (Math.cos(elapsed/80.0) / 2);
            }
            if(sprites['scythe']) {
                if (elapsed % scytheBling > (scytheBling - (blingSpeed*delta))) {
                    sprites['scythe-bling'].alpha = 1;
                } else if(sprites['scythe-bling'].alpha > 0) {
                    sprites['scythe-bling'].alpha = 0;
                    scytheBling = ((100 * delta) + (Math.random() * (400 * delta)));
                }
            }
            if(sprites['lightsaber-green'] || sprites['lightsaber-blue'] || sprites['lightsaber-red']) {
                if (elapsed % lightsaberFlash > (lightsaberFlash - (blingSpeed*delta))) {
                    sprites['lightsaber-flash'].alpha = 1
                } else if(sprites['lightsaber-flash'].alpha > 0) {
                    sprites['lightsaber-flash'].alpha = 1 - (Math.cos(elapsed/10.0))
                    lightsaberFlash = ((100 * delta) + (Math.random() * (400 * delta)))
                }
            }
            if(sprites['led-antlers-red']) {
                var antlerBlink = elapsed % 100;
                sprites['led-antlers-red'].alpha = 0;
                sprites['led-antlers-green'].alpha = 0;
                sprites['led-antlers-blue'].alpha = 0;
                sprites['led-antlers-yellow'].alpha = 0;
                if(antlerBlink < 25) {
                    sprites['led-antlers-red'].alpha = 1;
                } else if(antlerBlink < 50) {
                    sprites['led-antlers-green'].alpha = 1;
                } else if(antlerBlink < 75) {
                    sprites['led-antlers-blue'].alpha = 1;
                } else {
                    sprites['led-antlers-yellow'].alpha = 1;
                }
            }
            if(sprites['bgfireworks']) {
                var fireworksBlink = elapsed % 125;
                sprites['fireworks1'].alpha = 0;
                sprites['fireworks2'].alpha = 0;
                sprites['fireworks3'].alpha = 0;
                sprites['fireworks4'].alpha = 0;
                sprites['fireworks5'].alpha = 0;
                if(fireworksBlink < 25) {
                    sprites['fireworks1'].alpha = 1;
                } else if(fireworksBlink < 50) {
                    sprites['fireworks2'].alpha = 1;
                } else if(fireworksBlink < 75) {
                    sprites['fireworks3'].alpha = 1;
                } else if(fireworksBlink < 100) {
                    sprites['fireworks5'].alpha = 1;
                } else {
                    sprites['fireworks4'].alpha = 1;
                }
            }
            if(sprites['sleigh']) {
                sprites['sleigh']._x = (elapsed % 500) - 100;
                sprites['sleigh']._y = 0 - (((elapsed % 500)) / 7);
            }
            if(sprites['invisible']) {
                sprites['invisible'].alpha = 1 - (Math.cos(elapsed/80.0));
                sprites['outline'].alpha = 1 - (Math.cos(elapsed/80.0) / 2);
                sprites['shading'].alpha = 1 - (Math.cos(elapsed/80.0) / 5);
                if(sprites['tail']) sprites['tail'].alpha = 1 - (Math.cos(elapsed/80.0));
                if(sprites['skeleton-tail']) ['skeleton-tail'].alpha = 1 - (Math.cos(elapsed/80.0));
            }
            if(sprites['cig'] || sprites['pipe']) {
                sprites['smoke1'].alpha = (Math.cos((elapsed)/150.0) * 0.8);
                sprites['smoke2'].alpha = Math.cos((elapsed+200)/150.0);
                sprites['smoke3'].alpha = Math.cos((elapsed+400)/150.0);
                sprites['smoke4'].alpha = Math.cos((elapsed+600)/150.0);
            }
            /**
             if (sprites['bg13']) {
        if(elapsed - 5 > glitchTimer && glitchFilter.slices !== 0) {
          var fast = Math.round((Math.random() * 100)) % 2;
          if(fast === 1) {
            glitchTimer = elapsed + ((10 + (Math.random() * 20)) * delta);
          } else {
            glitchTimer = elapsed + 100 + ((Math.random() * 250) * delta);
          }
          glitchFilter.slices = 0;
        } else if (elapsed > glitchTimer && glitchFilter.slices === 0) {
          glitchFilter.slices = 1 + Math.round(Math.random() * 5);
        }
      }**/
            if(sprites['chain']) {
                if (elapsed % bling1 > (bling1 - (blingSpeed*delta))) {
                    sprites['bling1'].alpha = 1;
                } else if(sprites['bling1'].alpha > 0) {
                    sprites['bling1'].alpha = 0;
                    bling1 = ((100 * delta) + (Math.random() * (400 * delta)));
                }

                if (elapsed % bling2 > (bling2 - (blingSpeed*delta))) {
                    sprites['bling2'].alpha = 1;
                } else if(sprites['bling2'].alpha > 0) {
                    sprites['bling2'].alpha = 0;
                    bling2 = ((100 * delta) + (Math.random() * (400 * delta)));
                }

                if (elapsed % bling3 > (bling3 - (blingSpeed*delta))) {
                    sprites['bling3'].alpha = 1;
                } else if(sprites['bling3'].alpha > 0) {
                    sprites['bling3'].alpha = 0;
                    bling3 = ((100 * delta) + (Math.random() * (400 * delta)));
                }
            }

            if(sprites['mod-body']) {
                if(elapsed - 1000 < lastInteraction) {
                    if (elapsed % cameraflash > (cameraflash - (blingSpeed*delta))) {
                        sprites['mod-flash'].alpha = 1;
                    } else {
                        sprites['mod-flash'].alpha = 0;
                        cameraflash = ((100 * delta) + (Math.random() * (400 * delta)));
                    }
                } else {
                    if(Math.round(cameraflash % 4) === 1) {
                        sprites['mod-flash'].alpha = 1;
                    } else {
                        sprites['mod-flash'].alpha = 0;
                    }
                }
            }

            let mood = (elapsed) % randomBlink;
            let nope = false;

            if(document.getElementById('rat-image-container')) {
                if ((document.getElementById('rat-image-container').getBoundingClientRect().top) < 0) {
                    nope = true;
                }
            }

            if(nope) {
                showMood('nope');
                if(sprites['bg11'] && (sprites['angelwings'] || sprites['demon-wings'] || sprites['batwings'] || sprites['wings-gold'])) {
                    if(sprites['angelwings']) sprites['angelwings']._y = 1 + Math.cos(elapsed / 50);
                    if(sprites['demon-wings']) sprites['demon-wings']._y = 1 + Math.cos(elapsed / 50);
                    if(sprites['batwings']) sprites['batwings']._y = 1 + Math.cos(elapsed / 50);
                    if(sprites['wings-gold']) sprites['wings-gold']._y = 1 + Math.cos(elapsed / 50);
                    setPosition(0, 7);
                } else if(sprites['bg20'] && sprites['horns'] && sprites['demon-wings']) {
                    sprites['demon-wings']._y = 1 + Math.cos(elapsed / 50);
                    setPosition(0, 7);
                } else if((sprites['spacesuit'] || (sprites['body-alien'] && sprites['eyes-alien'])) && sprites['bgstarscape']) {
                    let move_x = 128 + Math.cos(elapsed / 300.0) * (256.0 + 128.0);
                    let move_y = 15 + Math.cos(elapsed / 40.0) * 15.0;
                    setPosition(move_x, move_y);
                } else {
                    let move = Math.cos(awake / 100.0) * 4.0;
                    setPosition(move, 7);
                }
            } else if (filters.includes('sleeping') || elapsed - 1000 > lastInteraction) {
                if(sprites['tail']) {
                    sprites['tail']._y = (Math.tan(Math.min((elapsed - lastAwake), 30) / 60) * 45) + Math.cos(elapsed / 100.0) * 6.0;
                }
                if(sprites['skeleton-tail']) {
                    sprites['skeleton-tail']._y = (Math.tan(Math.min((elapsed - lastAwake), 30) / 60) * 45) + Math.cos(elapsed / 100.0) * 6.0;
                }
                showMood('rest', elapsed);
                lastAsleep = elapsed;
                if(sprites['bg11'] && (sprites['angelwings'] || sprites['demon-wings'] || sprites['batwings'] || sprites['wings-gold'])) {
                    if(sprites['angelwings']) sprites['angelwings']._y = 1 + Math.cos(elapsed / 50);
                    if(sprites['demon-wings']) sprites['demon-wings']._y = 1 + Math.cos(elapsed / 50);
                    if(sprites['batwings']) sprites['batwings']._y = 1 + Math.cos(elapsed / 50);
                    if(sprites['wings-gold']) sprites['wings-gold']._y = 1 + Math.cos(elapsed / 50);
                    setPosition(0, 7);
                } else if(sprites['bg20'] && sprites['horns'] && sprites['demon-wings']) {
                    sprites['demon-wings']._y = 1 + Math.cos(elapsed / 50);
                    setPosition(0, 7);
                } else if((sprites['spacesuit'] || (sprites['body-alien'] && sprites['eyes-alien'])) && sprites['bgstarscape']) {
                    let move_x = 128 + Math.cos(elapsed / 300.0) * (256.0 + 128.0);
                    let move_y = 15 + Math.cos(elapsed / 40.0) * 15.0;
                    setPosition(move_x, move_y);
                } else {
                    let move = Math.cos(awake / 100.0) * 4.0;
                    setPosition(move, 7);
                }
            } else {
                awake += delta;
                lastAwake = elapsed;
                if(sprites['skeleton-tail']) {
                    sprites['skeleton-tail']._y = 2 + (Math.tan((30 - Math.min((elapsed - lastAsleep), 30)) / 120) * 45) + Math.cos(elapsed / 50.0) * 4.0
                }
                if(sprites['tail']) {
                    sprites['tail']._y = 2 + (Math.tan((30 - Math.min((elapsed - lastAsleep), 30)) / 120) * 45) + Math.cos(elapsed / 50.0) * 4.0
                }
                let move_x = 0;
                let move_y = 0;
                moodOverwrite = false;
                if(sprites['bg11'] && (sprites['angelwings'] || sprites['demon-wings'] || sprites['batwings'] || sprites['wings-gold'])) {
                    move_y = 4 + Math.cos(awake / 50.0) * 4.0;
                    if(sprites['angelwings']) sprites['angelwings']._y = 1 + Math.cos(elapsed / 5);
                    if(sprites['demon-wings']) sprites['demon-wings']._y = 1 + Math.cos(elapsed / 5);
                    if(sprites['batwings']) sprites['batwings']._y = 1 + Math.cos(elapsed / 5);
                    if(sprites['wings-gold']) sprites['wings-gold']._y = 1 + Math.cos(elapsed / 5);
                } else if(sprites['bg20'] && sprites['horns'] && sprites['demon-wings']) {
                    move_y = 3 + Math.cos(awake / 50.0) * 3.0;
                    sprites['demon-wings']._y = 1 + Math.cos(elapsed / 5);
                } else if((sprites['spacesuit'] || (sprites['body-alien'] && sprites['eyes-alien'])) && sprites['bgstarscape']) {
                    move_x = 128 + Math.cos(elapsed / 300.0) * (256.0 + 128.0);
                    move_y = 15 + Math.cos(elapsed / 40.0) * 15.0;
                } else if(sprites['spiderweb']) {
                    move_x = 0;
                    if(awake % 200 > 190) {
                        moodOverwrite = 'nope';
                        move_x = -8 + (Math.cos(awake) * 16);
                    }
                    move_y = 8 + Math.cos(awake / 50.0) * 1.0;
                } else {
                    move_x = Math.cos(awake / 100.0) * 4.0;
                    move_y = 1 + Math.cos(awake / 50.0) * 1.0;
                }

                if(eating + (100*delta) > elapsed) {
                    showMood('eating');
                    eatingParticles(elapsed);
                    if(elapsed % 10 > 5) {
                        move_y += 5;
                    }

                    if(filters.includes('flipped')) {
                        sprites['food']._x = 108;
                        sprites['food']._y = 31;
                    } else {
                        sprites['food']._x = 108;
                        sprites['food']._y = 185;
                    }
                } else if(lastAsleep + (100*delta) > elapsed || happy > elapsed) {
                    showMood('happy');
                } else {
                    if (mood > (randomBlink - (10*delta))) {
                        showMood('blink');
                    } else {
                        if(moodOverwrite) {
                            showMood(moodOverwrite);
                        } else {
                            if (sprites['neutral'] && sprites['neutral'].alpha === 0) {
                                randomBlink = ((100 * delta) + (Math.random() * (400 * delta)));
                            }
                            showMood('neutral');
                        }
                    }
                }
                setPosition(move_x, move_y);
            }
            if(filters.includes('greyscale')) {
                matrixFilter.blackAndWhite(1);
            }
            if(filters.includes('invert')) {
                matrixFilter.negative();
            }
            _pixiApp.render();
        }
        _pixiApp.ticker.add(updateAnimation);
    });
    return _pixiApp;
};
<template>
  <p id="rat-image-container"></p>
</template>

<script>
import {Rat} from '../../Rat.js';
export default {
  name: 'Image',
  props: ['resize', 'equipment', 'ratId'],
  data() {
    return {
      canvasLoaded: false,
      instance: null,
    }
  },
  watch: {
    'ratId': function() {
      this.loadRat();
    },
    'equipment': function() {
      this.loadRat();
    }
  },
  mounted() {
    this.loadRat();
  },
  methods: {
    loadRat: function() {
      if(this.instance) {
        this.instance.destroy();
        this.instance = null;
        document.getElementById('rat-image-container').innerHTML = '';
      }
      if(this.ratId) {
        this.instance = Rat(
            document.getElementById('rat-image-container'),
            this.ratId,
            this.api_url
              + (this.$store.getters.config.chainId === 2000 ? '/nightmares/layers/':'/original/layers/')
              + this.ratId,
            this
        );
      } else if(this.equipment) {
        this.instance = Rat(
            document.getElementById('rat-image-container'),
            this.equipment,
            this.api_url
              + (this.$store.getters.config.chainId === 2000 ? '/nightmares/layers/':'/original/layers/')
              + this.equipment,
            this
        );
      }
    }
  }
}
</script>